import { AppCore } from "@/app/core";
import { createRoot } from "react-dom/client";

export const mountApp = () => {
  const documentRoot = document.getElementById("root");

  if (!documentRoot) {
    throw new Error(`[mountApp] Element with id="root" not found.`);
  }

  const root = createRoot(documentRoot);

  root.render(<AppCore />);
};
