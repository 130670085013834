import * as React from "react";
import { LayoutDashboard } from "lucide-react";
import { Button } from "@/components/ui/button";

export type SidebarItem = {
  title: string;
  icon: React.ElementType;
  variant: "default" | "ghost";
};

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  items: SidebarItem[];
}

export const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  toggleSidebar,
  items,
}) => (
  <div
    className={`fixed inset-y-0 left-0 z-50 w-64 transform bg-background transition-transform duration-200 ease-in-out ${isOpen ? "translate-x-0" : "-translate-x-full"} md:relative md:translate-x-0`}
  >
    <div className="flex h-14 items-center border-b px-4">
      <Button variant="ghost" className="w-full justify-start px-2">
        <LayoutDashboard className="mr-2 h-5 w-5" />
        <span>My App</span>
      </Button>
    </div>
    <nav className="space-y-2 p-4">
      {items.map((item, index) => (
        <Button
          key={index}
          variant={item.variant}
          className="w-full justify-start"
          onClick={toggleSidebar}
        >
          <item.icon className="mr-2 h-4 w-4" />
          {item.title}
        </Button>
      ))}
    </nav>
  </div>
);
