import * as React from "react";
import {
  LayoutDashboard,
  Settings,
  Users,
  HelpCircle,
  Bell,
  Search,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Sidebar } from "@/components/layout/sidebar";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { TooltipProvider } from "@/components/ui/tooltip";

interface NavItem {
  title: string;
  icon: React.ElementType;
  variant: "default" | "ghost";
}

const navItems: NavItem[] = [
  { title: "Dashboard", icon: LayoutDashboard, variant: "default" },
  { title: "Users", icon: Users, variant: "ghost" },
  { title: "Settings", icon: Settings, variant: "ghost" },
  { title: "Help", icon: HelpCircle, variant: "ghost" },
];

export const CoreLayout: React.FC = () => {
  const [isSidebarOpn, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction="horizontal"
        className="h-full max-h-[800px] items-stretch"
      >
        <Sidebar
          isOpen={isSidebarOpn}
          toggleSidebar={toggleSidebar}
          items={navItems}
        />
        <ResizableHandle disabled />
        <ResizablePanel defaultSize={40} minSize={30}>
          <Tabs defaultValue="all">
            <div className="flex items-center px-4 py-2">
              <h1 className="text-xl font-bold">Dashboard</h1>
              <TabsList className="ml-auto">
                <TabsTrigger value="all">All Items</TabsTrigger>
                <TabsTrigger value="active">Active</TabsTrigger>
              </TabsList>
            </div>
            <Separator />
            <div className="bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
              <form>
                <div className="relative">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input placeholder="Search items..." className="pl-8" />
                </div>
              </form>
            </div>
            <TabsContent value="all" className="m-0">
              <div className="p-4">
                <h2 className="text-lg font-semibold mb-4">All Items</h2>
                {/* Add your list of items here */}
                <p>Your list of all items goes here.</p>
              </div>
            </TabsContent>
            <TabsContent value="active" className="m-0">
              <div className="p-4">
                <h2 className="text-lg font-semibold mb-4">Active Items</h2>
                {/* Add your list of active items here */}
                <p>Your list of active items goes here.</p>
              </div>
            </TabsContent>
          </Tabs>
        </ResizablePanel>
        <ResizableHandle disabled />
        <ResizablePanel defaultSize={40} minSize={30}>
          <div className="flex items-center justify-between px-4 py-2">
            <h2 className="text-lg font-semibold">Item Details</h2>
            <Button variant="ghost" size="icon">
              <Bell className="h-4 w-4" />
            </Button>
          </div>
          <Separator />
          <div className="p-4">
            {/* Add your item details content here */}
            <p>Select an item to view its details here.</p>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  );
};
